import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
    state: {
        menuIsOpen: false,
        modalIsOpen: false,
        config: {},
        currentUser: null
    },
    mutations: {
        login(state, currentUser) {
            state.currentUser = currentUser
        },
        logout(state) {
            state.currentUser = null
        },
        saveConfig(state, config) {
            state.config = config
        },
        openModal(state) {
            state.modalIsOpen = true
        },
        closeModal(state) {
            state.modalIsOpen = false
        },
        toggleMenu(state) {
            state.menuIsOpen = !state.menuIsOpen
        },
        closeMenu(state) {
            state.menuIsOpen = false
        }
    },
    modules: {}
})

export default store
