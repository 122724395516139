<template>
  <button class="sm-menu-toggle" @click.prevent="$emit('toggle-menu')">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" focusable="false">
      <title>Menu</title>
      <path stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
            d="M4 7h22M4 15h22M4 23h22"></path>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'MenuToggleButton'
}
</script>

<style lang="css" scoped>
.sm-menu-toggle {
  display: none;
}

@media screen and (max-width: 39.9988em) {
  .sm-menu-toggle {
    cursor: pointer;
    display: inline-block;
    float: left;
    margin-left: 1em;
    margin-top: 1em;
    margin-right: -1em;
  }
}
</style>
