<template>
  <div :class="{'user-menu': true, 'hide-on-mobile': true, 'is-open': $store.state.menuIsOpen}">
    <div>
      <user-avatar :profile-picture="currentUser.profilePicture"/>
      {{ currentUser.displayedUsername }}

      <a @click.prevent="onLogout" :title="$t('app.sign-out')" class="logout">
        <i class="fas fa-sign-out-alt"></i>
      </a>
    </div>
  </div>
</template>

<script>
import API from '@/api'
import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'UserMenu',
  components: {
    UserAvatar,
  },
  methods: {
    onLogout: function () {
      API.logout().then(() => {
        API.setAuth(null)
        this.$store.commit('logout')
        this.$router.push({name: 'Login'})
      })
    }
  }
}
</script>

<style lang="css">
.user-menu {
  border-top: solid 1px #131212;
  color: #e1e1e1;
  padding: 1rem;
}

@media (max-width: 719px) {
  .hide-on-mobile {
    display: none;
  }

  .is-open {
    display: block !important;
  }
}

.logout {
  float: right;
  line-height: 40px;
}
</style>
