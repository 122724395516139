<template>
  <div>
    <div style="display: flex; align-items: center;">
      <h3 style="flex: 1">{{ $t('system.sessions') }}</h3>
      <span>
        {{ count }}
      </span>
    </div>
    <LineChartGenerator :chart-data="chartData"
                        :chart-options="chartOptions"
                        chartId="session-chart"
                        datasetIdKey="session-dataset"
                        :height="183"
                        :width="365"
                        v-if="dataLoaded"/>
  </div>
</template>

<script>
import API from '@/api'
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

function genDate(origin, offset) {
  let offsetDate = new Date(origin.getTime())
  offsetDate.setDate(offsetDate.getDate() - offset)
  return offsetDate
}

function cacheKey(date) {
  return date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate()
}

export default {
  components: {
    LineChartGenerator
  },
  props: {
    from: Date,
    to: Date
  },
  data() {
    return {
      sessionsStats: [],
      dataLoaded: false,
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#ccc',
              beginAtZero: true
            }
          },
          y: {
            ticks: {
              color: '#ccc',
              beginAtZero: true,
              stepSize: 10
            }
          }
        },
        animation: {
          duration: 0 // general animation time
        },
        hover: {
          animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0, // animation duration after a resize
      },
      refreshMutex: false
    }
  },
  mounted() {
    this.refreshData()
  },
  watch: {
    from: function () {
      this.refreshData()
    },
    to: function () {
      this.refreshData()
    }
  },
  computed: {
    count: function () {
      let c = 0
      this.sessionsStats.forEach((e) => {
        c += e.sessionCount
      })
      return c
    }
  },
  methods: {
    refreshData: function () {
      if (this.refreshMutex) {
        return
      }
      this.refreshMutex = true
      this.dataLoaded = false

      const delta = Math.trunc((this.to.getTime() - this.from.getTime()) / (1000 * 3600 * 24))
      API.sessionsStats(this.from, this.to).then(stats => {
        this.refreshMutex = false
        this.sessionsStats = stats['sessionStats']
        let data = []
        let labels = []
        let dataCache = {}
        stats['sessionStats'].forEach(e => {
          dataCache[cacheKey(new Date(e.sessionDay))] = e.sessionCount
        })
        for (let i = delta; i >= 0; i--) {
          let d = genDate(this.to, i)
          labels.push(new Intl.DateTimeFormat().format(d))
          let k = cacheKey(d)
          if (k in dataCache) {
            data.push(dataCache[k])
          } else {
            data.push(0)
          }
        }

        this.chartData.labels = labels
        this.chartData.datasets = [
          {
            label: this.$t('system.sessions'),
            borderColor: "#f26f32",
            data,
            fill: false
          }
        ]
        this.dataLoaded = true
      })
    }
  }
}
</script>
