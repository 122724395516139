import eventBus from '@/eventBus'
import {v4 as uuidv4} from 'uuid'
import mixinDeep from 'mixin-deep'

class API {
    constructor() {
        this.baseURL = ''
        this.accessToken = null
        this.agent = {
            app: '',
            serial: this.deviceSerial()
        }
    }

    setBaseURL(baseURL) {
        this.baseURL = baseURL
    }

    setApp(app) {
        this.agent.app = app
    }

    setAuth(auth) {
        if (auth === null) {
            this.accessToken = null
            localStorage.removeItem('refresh_token')
        } else {
            this.accessToken = {
                value: auth.accessToken,
                expiresAt: auth.expiresAt
            }
            localStorage.setItem('refresh_token', auth.refreshToken)
        }
    }

    deviceSerial() {
        let deviceSerial = localStorage.getItem('device_serial')
        if (!deviceSerial) {
            deviceSerial = uuidv4()
            localStorage.setItem('device_serial', deviceSerial)
        }
        return deviceSerial
    }

    getRefreshToken() {
        return localStorage.getItem('refresh_token')
    }

    baseHeaders(accessToken) {
        const headers = {}
        const nucorderAgent = this.agent
        if (accessToken) {
            headers.Authorization = 'Bearer ' + accessToken.value
        }
        headers['X-Nucorder-Agent'] = JSON.stringify(nucorderAgent)
        return headers
    }

    apiFetch(input, init, skipAuth = false) {
        if (!skipAuth) {
            const refreshToken = this.getRefreshToken()
            if (refreshToken !== null && (this.accessToken === null || new Date() >= new Date(this.accessToken.expiresAt))) {
                // Refresh token
                return this.refreshAccessToken(refreshToken).then(res => {
                    switch (res.status) {
                        case 401:
                            this.setAuth(null)
                            eventBus.emit('api_logout')
                            return res
                        case 200:
                            return res.json().then(authRes => {
                                this.setAuth(authRes)
                                return this.apiFetch(input, init)
                            })
                        default:
                            return res
                    }
                })
            }
        }
        return fetch(this.baseURL + input, mixinDeep({headers: this.baseHeaders(this.accessToken)}, init)).then(res => {
            if (res.status === 403) {
                eventBus.emit('api_logout')
            }
            return res
        })
    }

    refreshAccessToken(refreshToken) {
        if (refreshToken === null) {
            return Promise.reject(new Error('empty refresh token'))
        }
        return this.apiFetch('v1/auth/refresh', {
            method: 'POST',
            body: JSON.stringify({
                refreshToken
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }, true)
    }

    health() {
        return this.apiFetch('v1/health').then((res) => res.text())
    }

    validateAccount(token) {
        return this.apiFetch('v1/accounts/validate', {
            method: 'POST',
            body: JSON.stringify({
                token
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json())
    }

    login(form) {
        return this.apiFetch('v1/auth', {
            method: 'POST',
            body: JSON.stringify({
                email: form.email,
                password: form.password
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json()).catch((res) => res.json())
    }

    logout() {
        return this.apiFetch('v1/auth', {
            method: 'DELETE'
        })
    }

    me() {
        return this.apiFetch('v1/me', {}).then(res => res.status === 200 ? res.json() : null).catch(() => null)
    }

    myDevices() {
        return this.apiFetch('v1/me/devices', {}).then(res => res.json())
    }

    mySubscription() {
        return this.apiFetch('v1/me/subscription', {})
            .then((res) => res.text())
            .then((text) => text.length ? JSON.parse(text) : null)
    }

    beginSubscription(subscriptionForm) {
        return this.apiFetch('v1/me/subscription', {
            method: 'POST',
            body: JSON.stringify(subscriptionForm),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json())
    }

    disconnectMyDevice(sessionId) {
        return this.apiFetch('v1/devices/' + sessionId, {
            method: 'DELETE'
        })
    }

    config(current) {
        let queryString = ''
        if (current) {
            queryString = '?current=true'
        }
        return this.apiFetch('v1/config' + queryString, {}, true).then(res => res.json())
    }

    newsPostList(page, limit) {
        return this.apiFetch('v1/news?p=' + page + '&limit=' + limit, {}).then(res => res.json())
    }

    followSuggestions(limit) {
        return this.apiFetch('v1/me/follows/suggestions?limit=' + limit, {}).then(res => res.json())
    }

    followSuggestionsByCountry(limit) {
        return this.apiFetch('v1/me/follows/suggestions/country?limit=' + limit, {}).then(res => res.json())
    }

    followSuggestionsByCity() {
        return this.apiFetch('v1/me/follows/suggestions/city?limit=5', {}).then(res => res.json())
    }

    accountByIdExtended(id) {
        return this.apiFetch('v1/accounts/' + id, {}).then(res => res.json())
    }

    accountById(id) {
        return this.apiFetch('v1/accounts/' + id + '/public', {}).then(res => res.json())
    }

    deleteAccount(id) {
        return this.apiFetch('v1/accounts/' + id, {
            method: 'DELETE'
        })
    }

    devicesByAccountId(id) {
        return this.apiFetch('v1/devices/accounts/' + id, {}).then(res => res.json())
    }

    subscriptionsByAccountId(id) {
        return this.apiFetch('v2/accounts/' + id + '/subscriptions', {}).then(res => res.json())
    }

    newsPostById(id) {
        return this.apiFetch('v1/news/' + id, {}).then(res => res.json())
    }

    deleteNewsPost(id) {
        return this.apiFetch('v1/news/' + id, {
            method: 'DELETE'
        })
    }

    latestNews() {
        return this.apiFetch('v1/news/latest', {}).then(res => res.json())
    }

    createNewsPost(newsPost) {
        return this.apiFetch('v1/news', {
            method: 'POST',
            body: JSON.stringify(newsPost),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json())
    }

    updateNewsPost(id, newsPost) {
        return this.apiFetch('v1/news/' + id, {
            method: 'PUT',
            body: JSON.stringify(newsPost),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json())
    }

    sessionsList() {
        return this.apiFetch('v1/sessions', {}).then(res => res.json())
    }

    availableProducts() {
        return this.apiFetch('v1/me/available-products', {}).then(res => res.json())
    }

    products(limit) {
        let queryString = ''
        if (limit) {
            queryString = '?size=' + limit
        }
        return this.apiFetch('v1/products' + queryString, {}).then((response) => response.json())
    }

    product(id) {
        return this.apiFetch('v1/products/' + id, {}).then((response) => response.json())
    }

    productLocales(id) {
        return this.apiFetch('v1/products/' + id + '/locale', {}).then((response) => response.json())
    }

    enableProduct(id) {
        return this.apiFetch('v1/products/' + id + '/enable', {
            method: 'POST'
        })
    }

    disableProduct(id) {
        return this.apiFetch('v1/products/' + id + '/disable', {
            method: 'POST'
        })
    }

    deleteProduct(id) {
        return this.apiFetch('v1/products/' + id, {
            method: 'DELETE'
        })
    }

    forgotPassword(form) {
        return this.apiFetch('v1/accounts/forgot-password', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json())
    }

    recoverPassword(form) {
        return this.apiFetch('v1/accounts/recover-password', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json())
    }

    createAccount(form) {
        return this.apiFetch('v1/accounts', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }

    changePassword(form) {
        return this.apiFetch('v1/me/password', {
            method: 'PUT',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }

    changeBirthdate(form) {
        return this.apiFetch('v1/me/birthdate', {
            method: 'PUT',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }

    changeLocale(form) {
        return this.apiFetch('v1/me/locale', {
            method: 'PUT',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }

    validateSubscription(subscriptionId) {
        return this.apiFetch('v1/subscriptions/' + subscriptionId + '/validate', {
            method: 'POST'
        }).then(res => res.json())
    }

    changeUsername(form) {
        return this.apiFetch('v1/me/username', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }

    appstart() {
        return this.apiFetch('v1/me/appstart', {}).then(res => res.text())
    }

    productTokens() {
        return this.apiFetch('v1/product-tokens', {}).then((response) => response.json())
    }

    claimProductToken(body) {
        return this.apiFetch('v1/subscriptions/tokens/claim', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(res => res.json())
    }

    system() {
        return this.apiFetch('v1/system', {
            method: 'GET'
        }).then(res => res.json())
    }

    activeBanners() {
        return this.apiFetch('v1/banners/active', {}).then((response) => response.json())
    }

    privileges() {
        return this.apiFetch('v1/privileges', {
            method: 'GET'
        }).then(res => res.json())
    }

    updatePrivileges(id, privilegesForm) {
        return this.apiFetch('v1/accounts/' + id + '/privileges', {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(privilegesForm)
        }).then(res => res.json())
    }

    roomProviders() {
        return this.apiFetch('v1/providers', {}).then((response) => response.json())
    }

    regions() {
        return this.apiFetch('v1/regions', {}).then((response) => response.json())
    }

    deleteRegion(regionId) {
        return this.apiFetch('v1/regions/' + regionId, {
            method: 'DELETE'
        }).then(res => res.json())
    }

    createRegion(regionForm) {
        return this.apiFetch('v1/regions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(regionForm)
        }).then(res => res.json())
    }

    availabilityZones() {
        return this.apiFetch('v1/az', {}).then((response) => response.json())
    }

    deleteAvailabilityZone(azId) {
        return this.apiFetch('v1/az/' + azId, {
            method: 'DELETE'
        }).then(res => res.json())
    }

    createAvailabilityZone(azForm) {
        return this.apiFetch('v1/az', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(azForm)
        }).then(res => res.json())
    }

    changeAccountPassword(id, changePasswordForm) {
        return this.apiFetch('v1/accounts/' + id + '/password', {
            method: 'PUT',
            body: JSON.stringify(changePasswordForm),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }

    syncAccount(id) {
        return this.apiFetch('v1/accounts/' + id + '/sync', {
            method: 'POST'
        })
    }

    createProduct(product) {
        return this.apiFetch('v1/products', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(product)
        }).then(res => res.json())
    }

    updateProductLocale(productId, locale) {
        return this.apiFetch('v1/products/' + productId + '/locale', {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(locale)
        })
    }

    updateProduct(id, product) {
        return this.apiFetch('v1/products/' + id, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(product)
        }).then(res => res.json())
    }

    followers(accountId) {
        return this.apiFetch('v1/accounts/' + accountId + '/followers', {}).then((response) => response.json())
    }

    profile(accountId) {
        return this.apiFetch('v1/profiles/' + accountId, {}).then((response) => response.json())
    }

    updatePreferences(preferencesForm) {
        return this.apiFetch('v1/me/preferences', {
            method: 'POST',
            body: JSON.stringify(preferencesForm),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }

    sessionPosts() {
        return this.apiFetch('v1/session-posts', {}).then((response) => response.json())
    }

    quitOpenRehearsalRoom(sessionId) {
        return this.apiFetch('v1/open-rehearsal/' + sessionId + '/quit', {
            method: 'POST'
        })
    }

    updateProfilePicture(blob) {
        const formData = new FormData()

        formData.append('file', blob, 'avatar.jpg')
        return this.apiFetch('v1/me/profile-picture', {
            method: 'POST',
            body: formData
        }).then(res => res.json())
    }

    abTests() {
        return this.apiFetch('v1/ab-tests', {}).then((response) => response.json())
    }

    createAbTest(abTest) {
        return this.apiFetch('v1/ab-tests', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(abTest)
        }).then(res => res.json())
    }

    deleteAbTest(userId) {
        return this.apiFetch('v1/ab-tests/' + userId, {
            method: 'DELETE'
        })
    }

    countries() {
        return this.apiFetch('v1/config/countries', {}).then((response) => response.json())
    }

    socialNetworks() {
        return this.apiFetch('v1/config/social-networks', {}).then((response) => response.json())
    }

    updateProfile(profileForm) {
        return this.apiFetch('v1/me/profile', {
            method: 'PUT',
            body: JSON.stringify(profileForm),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }

    updateMusicSkills(musicSkillsForm) {
        return this.apiFetch('v1/me/music-skills', {
            method: 'PUT',
            body: JSON.stringify(musicSkillsForm),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }

    createServer(server) {
        return this.apiFetch('v1/servers', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(server)
        }).then(res => res.json())
    }

    provisionServer(req) {
        return this.apiFetch('v1/servers/provision', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        }).then(res => res.text()).then((text) => text.length ? JSON.parse(text) : {})
    }

    startServer(id) {
        return this.apiFetch('v1/servers/' + id + '/start', {
            method: 'POST'
        })
    }

    pauseServer(id) {
        return this.apiFetch('v1/servers/' + id + '/pause', {
            method: 'POST'
        })
    }

    deleteServer(id) {
        return this.apiFetch('v1/servers/' + id, {
            method: 'DELETE'
        })
    }

    updateServerManagedCerts(id) {
        return this.apiFetch('v1/servers/' + id + '/update-certs', {
            method: 'POST'
        })
    }

    follows() {
        return this.apiFetch('v1/me/follows', {}).then((response) => response.json())
    }

    follow(userId) {
        return this.apiFetch('v1/me/followers/' + userId, {
            method: 'POST'
        })
    }

    unfollow(userId) {
        return this.apiFetch('v1/me/followers/' + userId, {
            method: 'DELETE'
        })
    }

    blockedUsers() {
        return this.apiFetch('v1/me/blocked', {}).then((response) => response.json())
    }

    block(userId) {
        return this.apiFetch('v1/me/blocked/' + userId, {
            method: 'POST'
        })
    }

    unblock(userId) {
        return this.apiFetch('v1/me/blocked/' + userId, {
            method: 'DELETE'
        })
    }

    accountIdByUsername(username) {
        return this.apiFetch('v1/profiles/username/' + username, {}).then((response) => response.json())
    }

    pingRoom(roomId) {
        return this.apiFetch('v1/rooms/' + roomId + '/ping', {
            method: 'POST'
        })
    }

    pingOnline() {
        return this.apiFetch('v1/me/ping', {
            method: 'POST'
        })
    }

    countOnlineUsers() {
        return this.apiFetch('v1/metrics/accounts/online').then((response) => response.json())
    }

    onlineUsers() {
        return this.apiFetch('v1/accounts/online').then((response) => response.json())
    }

    serverHealth(id) {
        return this.apiFetch('v1/servers/' + id + '/healthcheck', {
            method: 'GET'
        }).then(res => res.json())
    }

    createProductToken(productToken) {
        return this.apiFetch('v1/product-tokens', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(productToken)
        }).then(res => res.json())
    }

    deleteProductToken(id) {
        return this.apiFetch('v1/product-tokens/' + id, {
            method: 'DELETE'
        })
    }

    startOpenRehearsalRoom(form) {
        return this.apiFetch('v1/open-rehearsal/start', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form)
        }).then(res => res.json())
    }

    nalsVersions() {
        return this.apiFetch('v1/nals/versions', {}).then((response) => response.json())
    }

    enableNalsVersion(version) {
        return this.apiFetch('v1/nals/versions/' + version + '/enable', {
            method: 'POST'
        })
    }

    profilePictureFromUsername(playerName) {
        return this.apiFetch('v1/accounts/' + playerName + '/picture', {})
            .then(res => res.ok ? res.text() : '')
            .then(res => res === '' ? null : res)
            .catch(() => null)
    }

    startSelfCheckSession() {
        return this.apiFetch('v1/selfcheck/start', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }

    myInvites() {
        return this.apiFetch('v1/me/invitations', {
            method: 'GET'
        }).then(res => res.json())
    }

    joinOpenRehearsalSession(sessionId) {
        return this.apiFetch('v1/open-rehearsal/' + sessionId + '/join', {
            method: 'POST'
        }).then((response) => response.json())
    }

    inviteToOpenRehearsalSession(sessionId, form) {
        return this.apiFetch('v1/open-rehearsal/' + sessionId + '/invite', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form)
        }).then(res => res.json())
    }

    closeSelfcheckSession(sessionId) {
        return this.apiFetch('v1/selfcheck/' + sessionId + '/close', {method: 'POST'})
    }

    inviteesList(sessionId, filter) {
        let filterString = ''
        if (filter !== '') {
            filterString = '?filter=' + filter
        }
        return this.apiFetch('v1/rooms/' + sessionId + '/invitees' + filterString, {}).then(res => res.json())
    }

    sendConfirmEmail(id) {
        return this.apiFetch('v1/accounts/' + id + '/email', {method: 'POST'})
    }

    allBanners() {
        return this.apiFetch('v1/banners', {}).then((response) => response.json())
    }

    deleteBanner(id) {
        return this.apiFetch('v1/banners/' + id, {
            method: 'DELETE'
        })
    }

    closeSelfcheckSessionBeacon(sessionId) {
        const headers = {
            type: 'application/json'
        }
        const blob = new Blob([], headers)
        navigator.sendBeacon(this.baseURL + 'v1/selfcheck/' + sessionId + '/close', blob)
    }

    quitOpenRehearsalRoomBeacon(sessionId) {
        const headers = {
            type: 'application/json'
        }
        const blob = new Blob([], headers)
        navigator.sendBeacon(this.baseURL + 'v1/open-rehearsal/' + sessionId + '/quit', blob)
    }

    servers() {
        return this.apiFetch('v1/servers', {
            method: 'GET'
        }).then(res => res.json())
    }

    createBanner(form) {
        const formData = new FormData()
        formData.append('small', form.smallFile)
        if (form.largeFile !== null) {
            formData.append('large', form.largeFile)
        }
        formData.append('banner', new Blob([JSON.stringify({
            rank: form.rank,
            link: form.link
        })], {
            type: 'application/json'
        }))

        return this.apiFetch('v1/banners', {
            method: 'POST',
            body: formData
        }).then(res => res.json())
    }

    updateCoverPicture(blob) {
        const formData = new FormData()
        formData.append('file', blob, 'cover.jpg')

        return this.apiFetch('v1/me/cover-picture', {
            method: 'POST',
            body: formData
        }).then(res => res.json())
    }

    updateAccountValidationStatus(id, isValid) {
        return this.apiFetch('v1/accounts/' + id + '/valid?state=' + isValid, {method: 'POST'})
    }

    updateVerificationStatus(id, isVerified) {
        return this.apiFetch('v1/accounts/' + id + '/verified?state=' + isVerified, {method: 'POST'})
    }

    accounts(page, filters) {
        const params = new URLSearchParams({
            p: page,
            ...filters
        })
        return this.apiFetch('v1/accounts?' + params.toString(), {method: 'GET'})
            .then(res => res.json())
    }

    subscriptions(page, filter) {
        let queryString = '?p=' + page
        if (filter !== null && filter !== '') {
            queryString += '&filter=' + filter
        }
        return this.apiFetch('v1/subscriptions' + queryString, {method: 'GET'})
            .then(res => res.json())
    }

    addRoomMetrics(roomId, minPingInMs, maxPingInMs, averagePingInMs, sigmaPingInMs) {
        return this.apiFetch('v1/rooms/' + roomId + '/metrics', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                minPingInMs,
                maxPingInMs,
                averagePingInMs,
                sigmaPingInMs
            })
        })
    }

    downloadNalsl(os, target) {
        return this.apiFetch('v1/nals/download?os=' + os + '&target=' + target, {
            method: 'GET'
        }).then(res => res.text())
    }

    searchUsers(page, filters) {
        const params = new URLSearchParams({
            p: page,
            ...filters
        })
        return this.apiFetch('v1/search?' + params.toString(), {
            method: 'GET'
        }).then(res => res.json())
    }

    indexProfiles() {
        return this.apiFetch('v1/search/index', {
            method: 'POST'
        }).then(res => res.json())
    }

    appVersion() {
        const defaultVersionPackage = {
            version: '0.0.0',
            buildDate: Date.now()
        }
        return fetch(`${window.location.origin}/version.json?${Date.now()}`, {method: 'GET'})
            .then(res => res.json())
            .catch(() => new Promise(() => defaultVersionPackage))
    }

    musicStyles() {
        return this.apiFetch('v1/music-styles', {
            method: 'GET'
        }).then(res => res.json())
    }

    musicSkills() {
        return this.apiFetch('v1/music-skills', {
            method: 'GET'
        }).then(res => res.json())
    }

    testRehearsalSessions() {
        return this.apiFetch('v1/test-rehearsal', {
            method: 'GET'
        }).then(res => res.json())
    }

    createTestRehearsalSession(form) {
        return this.apiFetch('v1/test-rehearsal', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json())
    }

    closeTestRehearsalSession(sessionId) {
        return this.apiFetch('v1/test-rehearsal/' + sessionId, {method: 'DELETE'})
    }

    joinTestRehearsalSession(sessionId, password) {
        return this.apiFetch('v1/test-rehearsal/' + sessionId + '/join', {
            method: 'POST',
            body: JSON.stringify({
                password
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json())
    }

    quitTestRehearsalRoomBeacon(sessionId) {
        const headers = {
            type: 'application/json'
        }
        const blob = new Blob([], headers)
        navigator.sendBeacon(this.baseURL + 'v1/test-rehearsal/' + sessionId + '/quit', blob)
    }

    quitTestRehearsalRoom(sessionId) {
        return this.apiFetch('v1/test-rehearsal/' + sessionId + '/quit', {
            method: 'POST'
        })
    }

    publicRehearsalSessions() {
        return this.apiFetch('v1/public-rehearsal', {
            method: 'GET'
        }).then(res => res.json())
    }

    createPublicRehearsalSession(form) {
        return this.apiFetch('v1/public-rehearsal', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json())
    }

    closePublicRehearsalSession(sessionId) {
        return this.apiFetch('v1/public-rehearsal/' + sessionId, {method: 'DELETE'})
    }

    segmentsByAccountId(accountId) {
        return this.apiFetch('v1/accounts/' + accountId + '/segments', {
            method: 'GET'
        }).then(res => res.json())
    }

    formatDate(d) {
        return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
    }

    accountsStats(from, to) {
        return this.apiFetch('v1/accounts/stats?from=' + this.formatDate(from) + '&to=' + this.formatDate(to), {
            method: 'GET'
        }).then(res => res.json())
    }

    subscriptionsStats(from, to) {
        return this.apiFetch('v1/subscriptions/stats?from=' + this.formatDate(from) + '&to=' + this.formatDate(to), {
            method: 'GET'
        }).then(res => res.json())
    }

    sessionsStats(from, to) {
        return this.apiFetch('v1/sessions/stats?from=' + this.formatDate(from) + '&to=' + this.formatDate(to), {
            method: 'GET'
        }).then(res => res.json())
    }

    privateMessagesStats(from, to) {
        return this.apiFetch('v1/private-messages/stats?from=' + this.formatDate(from) + '&to=' + this.formatDate(to), {
            method: 'GET'
        }).then(res => res.json())
    }

    rawMusicSkills() {
        return this.apiFetch('v1/music-skills/raw', {
            method: 'GET'
        }).then(res => res.json())
    }

    updateMusicSkillRank(id, rank) {
        return this.apiFetch('v1/music-skills/' + id + '/rank', {
            method: 'PUT',
            body: JSON.stringify({
                rank
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }

    legalDocuments() {
        return this.apiFetch('v1/legal-documents', {
            method: 'GET'
        }).then(res => res.json())
    }

    legalDocumentById(id) {
        return this.apiFetch('v1/legal-documents/' + id, {
            method: 'GET'
        }).then(res => res.json())
    }

    legalDocumentLocalizations(id) {
        return this.apiFetch('v1/legal-documents/' + id + '/locale', {
            method: 'GET'
        }).then(res => res.json())
    }

    updateLegalDocument(id, form) {
        return this.apiFetch('v1/legal-documents/' + id, {
            method: 'PUT',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json())
    }

    updateLegalDocumentLocalization(id, form) {
        return this.apiFetch('v1/legal-documents/' + id + '/locale', {
            method: 'PUT',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    }

    deleteLegalDocument(id) {
        return this.apiFetch('v1/legal-documents/' + id, {
            method: 'DELETE'
        }).then((res) => res.json())
    }

    updatePublicRehearsalSessionCoverPicture(blob, sessionId) {
        const formData = new FormData()

        formData.append('file', blob, 'cover-picture.jpg')
        return this.apiFetch(`v1/public-rehearsal/${sessionId}/cover-picture`, {
            method: 'PUT',
            body: formData
        }).then(res => res.json())
    }

    addSubscription(form) {
        return this.apiFetch('v1/subscriptions', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    }

    helpSectionsList(page, filters) {
        const params = new URLSearchParams({
            p: page,
            ...filters
        })
        return this.apiFetch('v1/private/help-article-sections?' + params.toString(), {
            method: 'GET'
        }).then(res => res.json())
    }

    helpSectionsFlattenedList(page, size) {
        const params = new URLSearchParams({
            p: page,
            size
        })
        return this.apiFetch('v1/private/help-article-sections/all?' + params.toString(), {
            method: 'GET'
        }).then(res => res.json())
    }

    helpSectionById(id) {
        return this.apiFetch(`v1/private/help-article-sections/${id}`, {
            method: 'GET'
        }).then(res => res.json())
    }

    helpArticlesList(page, limit) {
        return this.apiFetch('v1/private/help-articles?p=' + page + '&size=' + limit, {
            method: 'GET'
        }).then(res => res.json())
    }

    helpArticleById(id) {
        return this.apiFetch(`v1/private/help-articles/${id}`, {
            method: 'GET'
        }).then(res => res.json())
    }

    helpArticleLocalizationByIdAndLocale(id, locale) {
        return this.apiFetch(`v1/private/help-articles/${id}/${locale}`, {
            method: 'GET'
        }).then(res => res.json())
    }

    helpSectionLocalizationByIdAndLocale(id, locale) {
        return this.apiFetch(`v1/private/help-article-sections/${id}/${locale}`, {
            method: 'GET'
        }).then(res => res.json())
    }

    createHelpSection(form) {
        return this.apiFetch('v1/private/help-article-sections', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }

    updateHelpSection(id, form) {
        return this.apiFetch(`v1/private/help-article-sections/${id}`, {
            method: 'PUT',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.status !== 200 ? res.json() : {})
    }

    deleteHelpSection(id) {
        return this.apiFetch(`v1/private/help-article-sections/${id}`, {
            method: 'DELETE',
        }).then(res => res.status !== 200 ? res.json() : {})
    }

    createHelpArticle(form) {
        return this.apiFetch('v1/private/help-articles', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
    }

    updateHelpArticle(id, form) {
        return this.apiFetch(`v1/private/help-articles/${id}`, {
            method: 'PUT',
            body: JSON.stringify(form),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.status !== 200 ? res.json() : {})
    }

    deleteHelpArticle(id) {
        return this.apiFetch(`v1/private/help-articles/${id}`, {
            method: 'DELETE',
        }).then(res => res.status !== 200 ? res.json() : {})
    }

    publishHelpArticle(id) {
        return this.apiFetch(`v1/private/help-articles/${id}/publish`, {
            method: 'POST',
        }).then(res => res.status !== 200 ? res.json() : {})
    }

    unpublishHelpArticle(id) {
        return this.apiFetch(`v1/private/help-articles/${id}/unpublish`, {
            method: 'POST',
        }).then(res => res.status !== 200 ? res.json() : {})
    }

    featureHelpArticle(id) {
        return this.apiFetch(`v1/private/help-articles/${id}/feature`, {
            method: 'POST',
        }).then(res => res.status !== 200 ? res.json() : {})
    }

    lowlightHelpArticle(id) {
        return this.apiFetch(`v1/private/help-articles/${id}/lowlight`, {
            method: 'POST',
        }).then(res => res.status !== 200 ? res.json() : {})
    }

    uploadHelpArticlePicture(blob, filename) {
        const formData = new FormData()

        formData.append('file', blob, filename)
        return this.apiFetch(`v1/private/help-articles/pictures`, {
            method: 'POST',
            body: formData
        }).then(res => res.json())
    }
}

export default new API()
