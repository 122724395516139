import { datadogLogs } from '@datadog/browser-logs'

export default function (datadogToken) {
    if (datadogToken === '') {
        console.log('logs report disabled')
        return
    }
    datadogLogs.init({
        clientToken: datadogToken,
        datacenter: 'eu',
        isCollectingError: true,
        sampleRate: 100
    })
}
