<template>
  <div :class="{'main-menu': true, 'hide-on-mobile': true, 'is-open': $store.state.menuIsOpen}">
    <ul>
      <li>
        <router-link to="/">{{ $t('menu.dashboard') }}</router-link>
      </li>
      <li>
        <strong><i class="fas fa-cogs"></i> {{ $t('menu.config') }}</strong>
        <ul>
          <li>
            <router-link to="/nals-versions">{{ $t('menu.nals-versions') }}</router-link>
          </li>
          <li>
            <router-link to="/music-skills">{{ $t('menu.music-skills') }}</router-link>
          </li>
        </ul>
      </li>
      <li>
        <strong><i class="fas fa-users"></i> {{ $t('menu.customers') }}</strong>
        <ul>
          <li>
            <router-link to="/users">{{ $t('menu.customers-search') }}</router-link>
          </li>
          <li>
            <router-link to="/online-users">{{ $t('menu.online-users') }}</router-link>
          </li>
        </ul>
      </li>
      <li>
        <strong><i class="fas fa-funnel-dollar"></i> {{ $t('menu.marketing') }}</strong>
        <ul>
          <li>
            <router-link to="/products">{{ $t('menu.products') }}</router-link>
          </li>
          <li>
            <router-link to="/product-tokens">{{ $t('menu.product-tokens') }}</router-link>
          </li>
        </ul>
      </li>
      <li>
        <strong><i class="fas fa-person-booth"></i> {{ $t('menu.rooms') }}</strong>
        <ul>
          <li>
            <router-link to="/ab-tests">{{ $t('menu.ab-tests') }}</router-link>
          </li>
          <li>
            <router-link to="/backstage">{{ $t('menu.backstage') }}</router-link>
          </li>
          <li>
            <router-link to="/providers">{{ $t('menu.providers') }}</router-link>
          </li>
          <li>
            <router-link to="/regions">{{ $t('menu.regions') }}</router-link>
          </li>
          <li>
            <router-link to="/availability-zones">{{ $t('menu.availability-zones') }}</router-link>
          </li>
          <li>
            <router-link to="/room-servers">{{ $t('menu.room-servers') }}</router-link>
          </li>
          <li>
            <router-link to="/test-rehearsal">{{ $t('menu.test-rehearsals') }}</router-link>
          </li>
          <li>
            <router-link to="/public-rehearsal">{{ $t('menu.public-rehearsals') }}</router-link>
          </li>
        </ul>
      </li>

      <li>
        <strong><i class="fas fa-newspaper"></i> {{ $t('menu.editorial') }}</strong>
        <ul>
          <li>
            <router-link to="/banners">{{ $t('menu.banners') }}</router-link>
          </li>
          <li>
            <router-link to="/news">{{ $t('menu.news') }}</router-link>
          </li>
          <li>
            <router-link to="/legal-documents">{{ $t('menu.legal-documents') }}</router-link>
          </li>
        </ul>
      </li>
      <li>
        <strong><i class="fas fa-hospital"></i> {{ $t('menu.customer-care') }}</strong>
        <ul>
          <li>
            <router-link to="/help-sections">{{ $t('menu.help-sections') }}</router-link>
          </li>
          <li>
            <router-link to="/help-articles">{{ $t('menu.help-articles') }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MainMenu'
}
</script>

<style lang="css" scoped>
.main-menu {
  flex-grow: 1;
  display: block;
  margin-top: 25px;
  overflow-y: auto;
}

.main-menu ul li > strong {
  color: #a1a1a1;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  margin-left: 1rem;
  display: block;
}

.main-menu ul li > strong > i {
  margin-right: 0.5em;
}

@media (max-width: 719px) {
  .hide-on-mobile {
    display: none;
  }

  .is-open {
    display: block !important;
  }
}
</style>
