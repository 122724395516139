<template>
  <div class="container">
    <h1>Not found</h1>
  </div>
</template>

<script>
export default {
  name: 'NotFoundView',
  metaInfo() {
    return {title: "Not found"}
  }
}
</script>