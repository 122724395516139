import { render, staticRenderFns } from "./NotFoundView.vue?vue&type=template&id=baec7b2c"
import script from "./NotFoundView.vue?vue&type=script&lang=js"
export * from "./NotFoundView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.38_css-loader@6.11.0_webpack@5.94.0__lodash@4.17.21__umooy3zhvlddiywks4xf7h5v5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports