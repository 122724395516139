<template>
  <div ref="overlay" class="dialog-overlay" v-show="isVisible">
    <transition name="fade">
      <div class="dialog" :class="activeClasses" role="dialog" v-if="isVisible">
        <component v-bind:is="currentModalComponent"
                   v-bind="currentModalProps"
                   @close="hideDialog"/>
      </div>
    </transition>
  </div>
</template>

<script>
import eventBus from '@/eventBus'

export default {
  name: 'ModalDialog',
  data: function () {
    return {
      currentModalComponent: null,
      currentModalProps: null,
      size: 'large',
      isVisible: false,
      isBright: false
    }
  },
  computed: {
    activeClasses: function () {
      return {
        tiny: this.size === 'tiny',
        small: this.size === 'small',
        large: this.size === 'large',
        bright: this.isBright
      }
    }
  },
  mounted() {
    eventBus.on('show_dialog', this.showDialog)
    eventBus.on('hide_dialog', this.hideDialog)
    document.addEventListener('keyup', this.onKeyUp)
    this.$refs.overlay.addEventListener('click', this.onClick)
  },
  beforeDestroy() {
    eventBus.off('show_dialog', this.showDialog)
    eventBus.off('hide_dialog', this.hideDialog)
    document.removeEventListener('keyup', this.onKeyUp)
    this.$refs.overlay.removeEventListener('click', this.onClick)
  },
  methods: {
    onKeyUp: function (e) {
      // Hide dialog on escape key press
      if (e.code === 'Escape' && this.isVisible) {
        this.hideDialog()
      }
    },
    onClick: function (e) {
      // On click on the overlay
      if (this.isVisible && e.target === this.$refs.overlay) {
        this.hideDialog()
      }
    },
    showDialog: function (e) {
      if (e.component === null) {
        return
      }
      if (this.$store.state.modalIsOpen) {
        return
      }
      this.currentModalComponent = e.component
      if (e.size === 'small' || e.size === 'large') {
        this.size = e.size
      } else {
        this.size = 'medium'
      }
      this.currentModalProps = e.props || {}
      this.isBright = e.isBright || false
      this.isVisible = true
      this.$store.commit('openModal')
    },
    hideDialog: function () {
      this.isVisible = false
      this.currentModalComponent = null
      this.currentModalProps = null
      this.$store.commit('closeModal')
    }
  }
}
</script>

<style lang="css" scoped>
.dialog-overlay {
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  background-color: rgba(10, 10, 10, .45);

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  height: 100vh;
}

.dialog {
  background-color: #222126;
  color: #fff;
  z-index: 1006;
  backface-visibility: hidden;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
  border-radius: 0.4rem;
}

@media print, screen and (min-width: 40em) {
  .dialog {
    width: 600px;
    max-width: 75rem;
  }

  .dialog.small {
    width: 50%;
    max-width: 75rem;
  }

  .dialog.large {
    width: 90%;
    max-width: 75rem;
  }
}

.dialog.bright {
  background-color: #e1e1e1;
  color: #222126;
}

@media screen and (max-width: 39.9988em) {
  .dialog {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    min-height: 100%;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}
</style>
