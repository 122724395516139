import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faLaptop,
    faMobile,
} from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'

// Register FontAwesome icon component
library.add(
    faLaptop,
    faMobile,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)