import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import DashboardView from '@/views/DashboardView.vue'
import {checkPrivilegesMiddleware} from '@/router/middleware'
import {closeMenuMiddleware} from "./middleware"
import NotFoundView from '@/views/NotFoundView'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    {
        name: 'NotFound',
        path: '*',
        component: NotFoundView
    },
    {
        path: '/',
        name: 'Dashboard',
        component: DashboardView,
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
        meta: {
            guestRequired: true
        }
    },
    {
        path: '/ab-tests',
        name: 'AbTests',
        component: () => import(/* webpackChunkName: "abtests" */ '../views/AbTestsView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/backstage',
        name: 'Backstage',
        component: () => import(/* webpackChunkName: "backstage" */ '../views/BackstageView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/banners',
        name: 'Banners',
        component: () => import(/* webpackChunkName: "banners" */ '../views/BannersView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/nals-versions',
        name: 'NalsVersions',
        component: () => import(/* webpackChunkName: "nalsVersions" */ '../views/NalsVersionsView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/news',
        name: 'NewsPosts',
        component: () => import(/* webpackChunkName: "newsPosts" */ '../views/NewsPostsView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        name: 'CreateNewsPost',
        path: '/news/new',
        component: () => import(/* webpackChunkName: "admin" */ '../views/EditNewsPostView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        name: 'EditNewsPost',
        path: '/news/:id/edit',
        component: () => import(/* webpackChunkName: "admin" */ '../views/EditNewsPostView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/products',
        name: 'Products',
        component: () => import(/* webpackChunkName: "products" */ '../views/ProductsView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/product-tokens',
        name: 'ProductsTokens',
        component: () => import(/* webpackChunkName: "productsTokens" */ '../views/ProductTokensView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/regions',
        name: 'Regions',
        component: () => import(/* webpackChunkName: "regions" */ '../views/RegionsView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/availability-zones',
        name: 'AvailabilityZones',
        component: () => import(/* webpackChunkName: "az" */ '../views/AvailabilityZonesView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/room-servers',
        name: 'RoomServers',
        component: () => import(/* webpackChunkName: "roomServers" */ '../views/RoomServersView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/providers',
        name: 'Providers',
        component: () => import(/* webpackChunkName: "providers" */ '../views/ProvidersView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/test-rehearsal',
        name: 'TestRehearsal',
        component: () => import(/* webpackChunkName: "testRehearsal" */ '../views/TestRehearsalView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/public-rehearsal',
        name: 'PublicRehearsal',
        component: () => import(/* webpackChunkName: "publicRehearsal" */ '../views/PublicRehearsalView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '../views/UsersView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/users/:id',
        name: 'ViewUserProfile',
        component: () => import(/* webpackChunkName: "users" */ '../views/UserProfileView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/music-skills',
        name: 'MusicSkills',
        component: () => import(/* webpackChunkName: "musicSkills" */ '../views/MusicSkillsView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/online-users',
        name: 'OnlineUsers',
        component: () => import(/* webpackChunkName: "onlineUsers" */ '../views/OnlineUsersView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        path: '/legal-documents',
        name: 'LegalDocuments',
        component: () => import(/* webpackChunkName: "legalDocuments" */ '../views/LegalDocumentsView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        name: 'CreateLegalDocument',
        path: '/legal-documents/new',
        component: () => import(/* webpackChunkName: "legalDocuments" */ '../views/EditLegalDocumentView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        name: 'EditLegalDocument',
        path: '/legal-documents/:id/edit',
        component: () => import(/* webpackChunkName: "legalDocuments" */ '../views/EditLegalDocumentView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        name: 'HelpArticles',
        path: '/help-articles',
        component: () => import(/* webpackChunkName: "helpArticles" */ '../views/HelpArticlesView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        name: 'HelpSections',
        path: '/help-sections',
        component: () => import(/* webpackChunkName: "helpArticles" */ '../views/HelpSectionsView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        name: 'AddHelpSection',
        path: '/help-sections/new',
        component: () => import(/* webpackChunkName: "helpArticles" */ '../views/EditHelpSectionView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        name: 'EditHelpSection',
        path: '/help-sections/:id/edit',
        component: () => import(/* webpackChunkName: "helpArticles" */ '../views/EditHelpSectionView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        name: 'AddHelpArticle',
        path: '/help-articles/new',
        component: () => import(/* webpackChunkName: "helpArticles" */ '../views/EditHelpArticleView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    },
    {
        name: 'EditHelpArticle',
        path: '/help-articles/:id/edit',
        component: () => import(/* webpackChunkName: "helpArticles" */ '../views/EditHelpArticleView.vue'),
        meta: {
            roles: ['CORE_ADMIN']
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// Hook that launches before each Route
router.beforeEach(async (to, from, next) => {
    await checkPrivilegesMiddleware(to, from, next)
    await closeMenuMiddleware(to, from, next)
    next()
})

export default router
