<template>
  <a class="logo">
    <img src="@/assets/img/logo.png" alt="logo"/>
  </a>
</template>

<script>
export default {
  name: 'BrandLogo'
}
</script>

<style lang="css">
.logo {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 60px;
}

@media (min-width: 720px) {
  .logo {
    margin-top: 1rem;
  }
}

@media (max-width: 719px) {
  .logo {
    flex: 1 1 0;
  }
}

.logo img {
  width: 48px;
}
</style>
