import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import API from './api'
import initLogs from './lib/logs'
import {checkDefaultLanguage} from './lib/i18n'

import './fontawesome'

Vue.config.productionTip = false

// Mixins
Vue.mixin({
    computed: {
        // currentUser gets the current user object from the store.
        currentUser: function () {
            return this.$store.state.currentUser
        }
    }
})

function initApp(currentUser, config) {
    initLogs(config.analytics.datadog)

    store.commit('saveConfig', config)

    let locale = checkDefaultLanguage()
    if (currentUser !== null) {
        locale = currentUser.locale
        store.commit('login', currentUser)
    }
    i18n.locale = locale

    new Vue({
        router,
        i18n,
        store,
        render: h => h(App)
    }).$mount('#app')
}

(() => {
    function retry() {
        // Get initial data
        Promise.all([
            API.me(),
            API.config()
        ]).then(([me, config]) => initApp(me, config))
            .catch((res) => {
                console.log(res)
                setTimeout(retry, 2000)
            })
    }

    API.setApp('backoffice')

    fetch('/config.json')
        .then(res => res.json())
        .then(configJSON => {
            API.setBaseURL(configJSON['api_url'])
            retry()
        })
        .catch(() => {
            API.setBaseURL(process.env.VUE_APP_API_URL)
            retry()
        })
})()
