<template>
  <div id="app" :class="{ 'no-scroll': $store.state.modalIsOpen }">
    <nav id="nav" aria-label="Main menu" v-if="currentUser !== null">
      <menu-toggle-button @toggle-menu="onToggleMenu"/>
      <brand-logo/>
      <main-menu/>
      <user-menu/>
    </nav>
    <main class="main-content">
      <div class="scrollable">
        <transition name="component-fade" mode="out-in">
          <router-view/>
        </transition>
      </div>
    </main>
    <modal-dialog/>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import ModalDialog from '@/components/ModalDialog'
import MenuToggleButton from '@/components/MenuToggleButton'
import MainMenu from '@/components/MainMenu'
import UserMenu from '@/components/UserMenu'
import BrandLogo from '@/components/BrandLogo'

export default {
  name: 'App',
  components: {
    UserMenu,
    ModalDialog,
    MenuToggleButton,
    MainMenu,
    BrandLogo,
  },
  mounted() {
    eventBus.on('api_logout', this.onApiLogout)
  },
  beforeDestroy() {
    eventBus.off('api_logout', this.onApiLogout)
  },
  methods: {
    onToggleMenu: function () {
      this.$store.commit('toggleMenu')
    },
    onApiLogout: function () {
      if (this.$store.state.currentUser) {
        this.$store.commit('logout')
        this.$router.push({ name: 'Login' })
      }
    }
  }
}
</script>

<style>
@import '~modern-normalize/modern-normalize.css';
@import "~cropperjs/dist/cropper.css";
@import "~flatpickr/dist/flatpickr.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~bootstrap/dist/css/bootstrap-grid.css";

@font-face {
  font-family: Montserrrat;
  src: local(Montserrat-Bold), url("./assets/fonts/Montserrat-Bold.ttf");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Overpass;
  src: local(Overpass-Regular), url("./assets/fonts/Overpass-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Overpass;
  src: local(Overpass-Bold), url("./assets/fonts/Overpass-Bold.ttf");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

.main-content h1, .main-content h2, .main-content h3, .main-content h4, .main-content h5, .header {
  font-family: Montserrrat, sans-serif;
  font-weight: bold;
  color: #fff;
}

#app {
  font-family: "Overpass", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  line-height: 1.5;
}

@media (min-width: 720px) {
  #app {
    display: flex;
  }
}

#nav {
  background-color: #010415;
  border-bottom: 1px solid rgba(204, 204, 220, 0.07);
}

@media (min-width: 720px) {
  #nav {
    border-bottom: none;
    border-right: 1px solid rgba(204, 204, 220, 0.07);
    display: flex;
    flex: 0 0 230px;
    width: 230px;
    flex-direction: column;
  }
}

#nav ul {
  padding: 0;
}

#nav ul li {
  display: block;
}

#nav ul li a {
  color: #eee;
  display: block;
  text-decoration: none;
  line-height: 2rem;
  padding: 0.4rem 1.5rem;
}

#nav ul li a:hover {
  background: #514e5a;
}

.main-content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 0;

  background-color: #222126;
  color: #eee;
}

.scrollable {
  overflow-y: auto;
  padding: 1em;
  height: 100%;
}

.no-scroll {
  overflow: hidden;
}

.avatar {
  background: #fff;
  border-radius: 50%;
  height: 40px;
}

.user-menu .avatar {
  vertical-align: middle;
}

/*==============================================================================
 * titles
 *==============================================================================
 */
h4 {
  font-size: 20px;
}

h3, h4 {
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: .5rem;
}

/*==============================================================================
 * transitions
 *==============================================================================
 */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}

.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}

/*==============================================================================
 * tables
 *==============================================================================
 */
table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0;
}

table thead tr {
  background: #313335;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}

tfoot td, tfoot th, thead td, thead th {
  padding: .5rem .625rem .625rem;
  font-weight: 700;
  text-align: left;
}

thead {
  background: #f8f8f8;
}

table tbody {
  color: #131415;
}

tbody, tfoot, thead {
  border: 1px solid #f1f1f1;
  background-color: #fefefe;
}

tbody td, tbody th {
  padding: .5rem .625rem .625rem;
}

tbody tr:nth-child(2n) {
  border-bottom: 0;
  background-color: #f1f1f1;
}

/*==============================================================================
 * buttons
 *==============================================================================
 */

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem;
  padding: .85em 1em;
  border: 1px solid transparent;
  transition: background-color .25s ease-out, color .25s ease-out;
  font-family: inherit;
  font-size: .9rem;
  -webkit-appearance: none;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  border-radius: 3px;
  background-color: #f26f32;
  color: #fefefe;
}

.button + .button {
  margin-left: 1rem;
}

.button > i {
  margin-right: 0.2em;
}

.button.warning {
  background-color: #ffae00;
  color: #0a0a0a;
}

.button.warning:hover {
  background-color: #cc8b00;
}

.button.primary {
  background-color: #f26f32;
  color: #fefefe;
}

.button, .button.primary:hover {
  background-color: #dc4f0e;
}

.button.secondary {
  background-color: #e9e9e9;
  color: #0a0a0a;
}

.button.secondary:hover {
  background-color: #bababa;
  color: #0a0a0a;
}

.button.alert {
  background-color: #c60f13;
  color: #fefefe;
}

.button.alert:hover {
  background-color: #9e0c0f;
}

.button.success {
  background-color: #5da423;
  color: #0a0a0a;
}

.button.success:hover {
  background-color: #4a831c;
}

a.button {
  text-decoration: none;
}

.button.disabled:focus, .button.disabled:hover, .button[disabled], .button[disabled]:focus, .button[disabled]:hover {
  background-color: #f26f32;
  color: #fefefe;
}

.button.disabled.secondary, .button.disabled.secondary:focus, .button.disabled.secondary:hover, .button[disabled].secondary, .button[disabled].secondary:focus, .button[disabled].secondary:hover {
  background-color: #e9e9e9;
  color: #0a0a0a;
}

.button.disabled.warning, .button.disabled.warning:focus, .button.disabled.warning:hover, .button[disabled].warning, .button[disabled].warning:focus, .button[disabled].warning:hover {
  background-color: #ffae00;
  color: #0a0a0a;
}

.button.disabled.success, .button.disabled.success:focus, .button.disabled.success:hover, .button[disabled].success, .button[disabled].success:focus, .button[disabled].success:hover {
  background-color: #5da423;
  color: #0a0a0a;
}

.button.disabled.alert, .button.disabled.alert:focus, .button.disabled.alert:hover, .button[disabled].alert, .button[disabled].alert:focus, .button[disabled].alert:hover {
  background-color: #c60f13;
  color: #fefefe;
}

.button.disabled, .button[disabled] {
  opacity: .25;
  cursor: not-allowed;
}

/*==============================================================================
 * forms
 *==============================================================================
 */
i.field-icon {
  color: #242a42;
  float: right;
  margin-right: 6px;
  margin-top: -42px;
  position: relative;
  z-index: 2;
}

i.field-icon:hover {
  cursor: pointer;
}

[type="color"], [type="date"], [type="datetime-local"], [type="datetime"], [type="email"], [type="month"], [type="number"], [type="password"], [type="search"], [type="tel"], [type="text"], [type="time"], [type="url"], [type="week"], textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: .5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, .1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow .5s, border-color .25s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea[rows] {
  height: auto;
}

textarea {
  max-width: 100%;
}

select {
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding: .5rem 1.5rem .5rem .5rem;
  transition: box-shadow .5s, border-color .25s ease-in-out;
}

select[multiple] {
  height: auto;
  background-image: none;
}

button {
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto;
}

[type="file"] {
  width: 100%;
}

[type="button"], [type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

[type="checkbox"], [type="file"], [type="radio"] {
  margin: 0 0 1rem;
}

label {
  display: block;
  margin: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.8;
}

.dialog .close-button {
  color: #fff;
  top: 1rem;
  font-size: 1.6em;
}

.close-button, .close-button.medium {
  right: 1rem;
  top: .5rem;
  font-size: 2em;
  line-height: 1;
}

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer;
}

.modal-title {
  text-align: center;
  padding: .8rem;
}

.modal-title > h4 {
  margin: 0;
}

.modal-content {
  border-top: 1px solid hsla(0, 0%, 100%, .1);
  padding: 1.5em;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.label {
  display: inline-block;
  padding: .33333rem .5rem;
  border-radius: 3px;
  font-size: .8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #f26f32;
  color: #fefefe;
}

.label.success {
  background: #5da423;
  color: #0a0a0a;
}

.label.alert {
  background-color: #c60f13;
  color: #fefefe;
}

ul, ol, dl {
  list-style-position: outside;
  line-height: 1.6;
}

a {
  cursor: pointer;
}

.float-left {
  float: left !important;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

.callout {
  position: relative;
  margin: 0 0 1rem;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, .25);
  background-color: #fff;
  color: #0a0a0a;
  border-radius: 3px;
}

.callout.alert {
  background-color: #fcd6d6;
  color: #0a0a0a;
}

.callout.success {
  background-color: #e1faea;
  color: #0a0a0a;
}

.callout.warning {
  background-color: #fff3d9;
  color: #0a0a0a;
}

.callout.primary {
  background-color: #d7ecfa;
  color: #0a0a0a;
}

.callout.secondary {
  background-color: #eaeaea;
  color: #0a0a0a;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.editor__contents img.ProseMirror-selectednode {
  outline: 3px solid #68CEF8;
}

.editor__contents {
  background: #fefefe;
  color: #0a0a0a;
  padding: 0.8rem;
}

.editor__contents h2, .editor__contents h3, .editor__contents h4 {
  color: #0a0a0a;
}
</style>
