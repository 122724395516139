<template>
  <div class="container-fluid">
    <div class="row">
      <table>
        <thead>
        <tr>
          <th></th>
          <th>{{ $t('system.state') }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody v-if="isLoading">
        <tr>
          <td colspan="3" class="text-center">
            {{ $t('system.loading') }}
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr>
          <td>{{ $t('system.users') }}</td>
          <td>
            <span v-html="$t('system.online-users', [onlineUsersCount])"></span><br/>
            <span v-html="$t('system.registered-users', [systemInfo.nbRegisteredUsers])"></span><br/>
            <span v-html="$t('system.active-subscriptions', [systemInfo.nbActiveSubscriptions, systemInfo.nbActiveInternalSubscriptions])"></span>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>{{ $t('system.sessions') }}</td>
          <td>
            <span v-html="$t('system.sessions-count', [systemInfo.nbMusicSessions])"></span><br/>
            <span v-html="$t('system.sessions-kpi-count', [systemInfo.countSessionRegistrationStats])"></span><br/>
            <span v-html="$t('system.active-players-with-low-latency-kpi', [systemInfo.countLowPingAccounts])"></span><br/>
          </td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row period-selector">
      <p>
        <input id="stats-period" type="text" />
      </p>
    </div>
    <div class="row charts">
      <div class="col col-12 col-xl-4 col-md-6 chart">
        <user-reg-chart :from="statsPeriod[0]" :to="statsPeriod[1]"/>
      </div>
      <div class="col col-12 col-xl-4 col-md-6 chart">
        <subscription-chart :from="statsPeriod[0]" :to="statsPeriod[1]"/>
      </div>
      <div class="col col-12 col-xl-4 col-md-6 chart">
        <session-chart :from="statsPeriod[0]" :to="statsPeriod[1]"/>
      </div>
      <div class="col col-12 col-xl-4 col-md-6 chart">
        <private-messages-chart :from="statsPeriod[0]" :to="statsPeriod[1]"/>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api'
import flatpickr from 'flatpickr'
import {French} from 'flatpickr/dist/l10n/fr'
import UserRegChart from '@/components/charts/UserRegChart'
import SubscriptionChart from '@/components/charts/SubscriptionChart'
import SessionChart from '@/components/charts/SessionChart'
import PrivateMessagesChart from '@/components/charts/PrivateMessagesChart'

export default {
  name: 'DashboardView',
  components: {
    UserRegChart,
    SubscriptionChart,
    SessionChart,
    PrivateMessagesChart,
  },
  metaInfo() {
    return {title: this.$t('system.page-title')}
  },
  data: function () {
    return {
      systemInfo: {},
      onlineUsersCount: 0,
      isLoading: true,
      refreshTicker: null,
      statsPeriod: [this.genDate(30), new Date()]
    }
  },
  mounted() {
    this.loadStats()
    this.refreshTicker = window.setInterval(() => {
      this.loadStats()
    }, 1000 * 60)

    if (this.currentUser.locale === 'fr') {
      flatpickr('#stats-period', {
        mode: 'range',
        altInput: true,
        altFormat: 'j F Y',
        locale: French,
        onChange: (selectedDates) => {
          if (selectedDates.length < 2) {
            return
          }
          this.statsPeriod = selectedDates
        },
        defaultDate: [this.genDate(30), new Date()]
      })
    } else {
      flatpickr('#stats-period', {
        mode: 'range',
        altInput: true,
        altFormat: 'F j, Y',
        onChange: (selectedDates) => {
          if (selectedDates.length < 2) {
            return
          }
          this.statsPeriod = selectedDates
        },
        defaultDate: [this.genDate(30), new Date()]
      })
    }
  },
  beforeDestroy() {
    window.clearInterval(this.refreshTicker)
  },
  methods: {
    loadStats: function () {
      API.system().then(res => {
        this.systemInfo = res
        this.isLoading = false
      })
      API.countOnlineUsers().then(count => {
        this.onlineUsersCount = count
      })
    },
    genDate: function (offset) {
      let offsetDate = new Date()
      offsetDate.setDate(offsetDate.getDate() - offset)
      return offsetDate
    }
  }
}
</script>

<style scoped lang="css">
.chart {
  padding: 10px
}

.period-selector p {
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .period-selector {
    display: flex;
  }

  .period-selector p {
    margin-left: auto;
    width: 25%;
  }
}
</style>