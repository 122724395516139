import store from '@/store'

export const closeMenuMiddleware = (to, from, next) => new Promise((resolve => {
    next()
    if (store.state.menuIsOpen) {
        store.commit('closeMenu')
    }
    resolve()
}))

/**
 * checkPrivilegesMiddleware ensures the user is allowed to see the requested page.
 * @param to
 * @param from
 * @param next
 * @returns {Promise}
 */
export const checkPrivilegesMiddleware = (to, from, next) => new Promise((resolve) => {
    if (store.state.currentUser !== null && to.meta.guestRequired) {
        next({name: 'hall'})
    } else if (to.meta.roles !== undefined && to.meta.roles.length > 0) {
        if (store.state.currentUser === null) {
            localStorage.setItem('redirectURL', to.fullPath)
            next({name: 'Login'})
            resolve()
            return
        }
        if (store.state.currentUser.privileges.some(privilege => to.meta.roles.includes(privilege))) {
            next()
        } else {
            next({name: 'NotFound'})
        }
    } else {
        next()
    }
    resolve()
})
