<template>
  <img :src="profilePicture" alt="avatar" :class="sizeClass" loading="lazy" v-if="profilePicture !== null"/>
  <img src="@/assets/img/profile/default-avatar.png" alt="avatar" :class="sizeClass" loading="lazy" v-else/>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    profilePicture: String,
    size: String
  },
  computed: {
    sizeClass: function () {
      switch (this.size) {
        case 'md':
          return 'avatar-md'
        case 'lg':
          return 'avatar-lg'
        default:
          return 'avatar'
      }
    }
  }
}
</script>
